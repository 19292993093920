<template>
  <AccordionItem :label="'Default Document Naming'" :item-key="1">
    <ConfirmDialog ref="confirmDialog" />
    <CModal
      size="lg"
      alignment="center"
      :visible="previewDialog"
      @close="previewDialogClose"
    >
      <CModalHeader>
        <CModalTitle>Default name for your contracts will be</CModalTitle>
      </CModalHeader>
      <CModalBody class="w-100">
        {{ preview }}
      </CModalBody>
      <CModalFooter>
        <CCol xs="12" style="text-align: center">
          <CButton
            color="primary"
            variant="outline"
            @click="previewDialogClose"
          >
            Close
          </CButton>
        </CCol>
      </CModalFooter>
    </CModal>

    <CRow>
      <CCol>
        <p>
          By default, the names of contracts documents are prepended with the
          Project Number and the Company Initials of who the documents has been
          assigned to. This ensures that contract documents have a unique name,
          while still making them easy to keep track of.
        </p>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="4" md="6" class="pt-2">
        <CFormLabel>
          Contract Document
          <CSpinner v-if="loading" component="span" size="sm" />
        </CFormLabel>
      </CCol>
      <CCol lg="3" md="6" style="text-align: right">
        <CButton
          color="dark"
          variant="ghost"
          style="color: #303c54"
          @click="previewName"
        >
          Review
          <CSpinner v-if="previewLoading" component="span" size="sm" />
          <img
            v-else
            src="@/assets/eye.svg"
            style="width: 20px; margin-bottom: 5px; margin-left: 5px"
          />
        </CButton>
      </CCol>
      <CCol xs="12" lg="7">
        <CFormInput
          v-model="form.default_document_name"
          :invalid="!!validationErrors.default_document_name"
          placeholder="{{Project.external/number}}-{{Company.initials}}"
          @blur="handleChangeField('default_document_name')"
        />
        <CFormText
          v-if="validationErrors.default_document_name"
          style="color: red"
          >{{ validationErrors.default_document_name }}</CFormText
        >
      </CCol>
    </CRow>
    <CRow
      class="justify-content-sm-center justify-content-md-start mx-0 mt-3 w-100 p-0"
    >
      <CCol lg="2" xxl="2" xs="12">
        <CButton color="primary" class="w-100" @click="editName">
          <CSpinner v-if="editLoading" component="span" size="sm" />
          Save
        </CButton>
      </CCol>
      <CCol lg="2" xxl="2" xs="12">
        <CButton variant="ghost" class="w-100" @click="handleCancel">
          Cancel
        </CButton>
      </CCol>
    </CRow>
  </AccordionItem>
</template>

<script>
import AccordionItem from '@/components/Page/AccordionItem'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { parseError } from '@/utils/api'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'

export default {
  name: 'DefaultComponentNaming',
  components: { AccordionItem, ConfirmDialog },
  inject: ['toast'],
  data() {
    return {
      form: {
        default_document_name: '',
      },
      originName: '',
      previewDialog: false,
      loading: false,
      previewLoading: false,
      editLoading: false,
      preview: '',
      validator: {},
      validationErrors: {},
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setRules()
      this.getName()
    })
  },
  methods: {
    setRules() {
      this.validator = validator({
        default_document_name: [rules.required, rules.strMax(255)],
      })
    },
    handleChangeField(field) {
      this.validationErrors[field] = this.validator.validate(
        field,
        this.form[field],
      )
    },
    getName() {
      this.loading = true
      this.$http.setting
        .getDocumentNameDefault()
        .then((response) => {
          this.form.default_document_name = response.data.default_document_name
          this.originName = response.data.default_document_name
        })
        .catch((err) => {
          err = parseError(err)
          this.toast('error', err.message)
        })
        .finally(() => (this.loading = false))
    },
    previewName() {
      const validationResult = this.validator.validateAll(this.form)
      if (!validationResult.hasErrors) {
        this.previewLoading = true
        this.$http.setting
          .previewDocumentNameDefault({ params: this.form })
          .then((response) => {
            this.preview = response.data.document_name_preview
            this.previewDialog = true
          })
          .catch((err) => {
            err = parseError(err)
            if (err.status === 422) {
              this.validationErrors = err.validationMessages
            } else {
              this.toast('error', err.message)
            }
          })
          .finally(() => (this.previewLoading = false))
      } else {
        this.validationErrors = validationResult.validationErrors
      }
    },
    editName() {
      const validationResult = this.validator.validateAll(this.form)
      if (!validationResult.hasErrors) {
        this.editLoading = true
        this.$http.setting
          .editDocumentNameDefault(this.form)
          .then((response) => {
            this.toast('success', response.data.message)
            this.getName()
          })
          .catch((err) => {
            err = parseError(err)
            if (err.status === 422) {
              this.validationErrors = err.validationMessages
            } else {
              this.toast('error', err.message)
            }
          })
          .finally(() => (this.editLoading = false))
      } else {
        this.validationErrors = validationResult.validationErrors
      }
    },
    isEditName() {
      return this.originName !== this.form.default_document_name
    },
    async handleCancel() {
      this.isEditName() ? await this.confirmCancel() : this.cancel()
    },
    async confirmCancel() {
      await this.$refs.confirmDialog
        .confirm({
          text: 'You have unsaved changes.',
          info: 'Are you sure, you want to cancel them?',
          reverse: true,
        })
        .then((res) => {
          if (res) this.cancel()
        })
    },
    cancel() {
      this.getName()
      this.validationErrors = {}
    },
    previewDialogClose() {
      this.previewDialog = false
      this.preview = ''
    },
  },
}
</script>

<style scoped></style>
