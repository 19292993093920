<template>
  <div class="settingDocumentTemplates">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Merge Fields </CBreadcrumbItem>
    </teleport>

    <CCard>
      <CCardBody>
        <EditMergeValue v-model:item="selectedItem" @update="fetchData" />
        <CRow>
          <CCol sm="12">
            <CAccordion :active-item-key="2">
              <ConfirmDialog ref="confirmDialog" />
              <DefaultComponentNaming ref="defaultComponentName" />
              <AccordionItem
                :mobile-label="'Merge Fields management'"
                :pc-classes="['mt-5']"
                :item-key="2"
              >
                <template #pc>
                  <CRow>
                    <CCol lg="6" xl="5" xxl="4">
                      <h4>Merge Fields management</h4>
                    </CCol>
                    <CCol xs="6" lg="3">
                      <CFormSelect v-model="selectedType" :options="types" />
                    </CCol>
                    <CCol
                      xs="6"
                      lg="3"
                      xl="4"
                      xxl="5"
                      class="text-end pe-5 pt-1"
                    >
                      <CButton color="primary" size="sm" @click="exportCodes()">
                        <CSpinner
                          v-if="exportLoading"
                          component="span"
                          size="sm"
                        />
                        Export All
                      </CButton>
                    </CCol>
                  </CRow>
                </template>
                <CRow class="pc-hide-768">
                  <CCol class="mt-2" xs="12">
                    <CFormSelect v-model="selectedType" :options="types" />
                  </CCol>
                  <CCol class="mt-4 px-5" xs="12">
                    <CButton
                      color="primary"
                      class="w-100"
                      @click="exportCodes()"
                    >
                      <CSpinner
                        v-if="exportLoading"
                        component="span"
                        size="sm"
                      />
                      Export All
                    </CButton>
                  </CCol>
                </CRow>
                <CRow class="mt-3 mx-0">
                  <CCol xs="12" style="padding-right: 0; padding-left: 0">
                    <SmartTable
                      :items="items"
                      :items-per-page="parseInt(perPage)"
                      :columns="columns"
                      :column-filter="false"
                      :sorter-value="sort"
                      :loading="loadingTable"
                      :active-page="activePage"
                      :per-page="perPage"
                      :pages="pages"
                      :clear-text="
                        search.length
                          ? 'No search results found'
                          : 'No Data yet'
                      "
                      @sorter-change="onSort"
                      @update-active-page="(value) => (activePage = value)"
                      @update-per-page="(value) => (perPage = value)"
                    >
                      <template #button="{ item }">
                        <CIcon
                          name="cil-pen"
                          class="pointer"
                          @click="() => (selectedItem = item)"
                        />
                      </template>
                    </SmartTable>
                  </CCol>
                </CRow>
              </AccordionItem>
            </CAccordion>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
