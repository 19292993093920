<template>
  <CModal
    alignment="center"
    :visible="showDialog"
    @close="dialogClose"
    @show="handleShowDialog"
  >
    <CModalHeader>
      <CModalTitle>Edit</CModalTitle>
    </CModalHeader>
    <CModalBody class="w-100">
      <CRow>
        <CCol xs="12">
          <CFormLabel>
              <strong>{{editItem.name}}:</strong>
          </CFormLabel>
          <CFormInput
            v-model="editItem.placeholder"
            :invalid="!!validationErrors.placeholder"
            @blur="handleChangeField('placeholder')"
            @keyup="formatPlaceholder()"
          />
          <CFormText style="color: red">
            {{ validationErrors.placeholder }}
          </CFormText>
        </CCol>
      </CRow>
    </CModalBody>
    <CModalFooter>
      <CCol xs="12" style="text-align: right" class="w-100">
        <CButton color="primary" @click="handleCheck">
          <CSpinner v-if="editLoading" component="span" size="sm" />
          Save
        </CButton>
        <CButton variant="ghost" @click="dialogClose"> Cancel </CButton>
      </CCol>
    </CModalFooter>
  </CModal>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { parseError } from '@/utils/api'

export default {
  name: 'EditMergeValue',
  inject: ['toast'],
  props: {
    item: { type: Object, default: () => {} },
  },
  emits: ['update:item', 'update'],
  data() {
    return {
      editItem: {},
      editLoading: false,
      validator: {},
      validationErrors: {},
    }
  },
  computed: {
    showDialog() {
      return Object.keys(this.item).length > 0
    },
  },

  mounted() {
    this.$nextTick(() => this.setRules())
  },
  methods: {
    formatPlaceholder() {
      let val = this.editItem.placeholder.replace(/\{/g, '').replace(/}/g, '')
      this.editItem.placeholder = val ? '{{' + val + '}}' : ''
    },
    setRules() {
      this.validator = validator({
        placeholder: [rules.required, rules.strMax(255)],
      })
    },
    handleChangeField(field) {
      this.validationErrors[field] = this.validator.validate(
        field,
        this.editItem[field],
      )
    },
    handleShowDialog() {
      this.editItem = JSON.parse(JSON.stringify(this.item))
    },
    dialogClose() {
      this.$emit('update:item', {})
    },
    handleCheck() {
      const validationResult = this.validator.validateAll(this.editItem)
      if (!validationResult.hasErrors) {
        this.handleEdit()
      } else {
        this.validationErrors = validationResult.validationErrors
      }
    },
    handleEdit() {
      const validationResult = this.validator.validateAll(this.editItem)
      if (!validationResult.hasErrors) {
        this.editLoading = true
        this.$http.setting
          .editShortCode({
            shortcode_id: this.editItem.id,
            placeholder: this.editItem.placeholder,
          })
          .then((response) => {
            this.dialogClose()
            this.$emit('update')
            this.toast('success', response.data.message)
          })
          .catch((err) => {
            err = parseError(err)
            if (err.status === 422) {
              this.validationErrors = err.validationMessages
            } else {
              this.toast('error', err.message)
            }
          })
          .finally(() => (this.editLoading = false))
      } else {
        this.validationErrors = validationResult.validationErrors
      }
    },
  },
}
</script>

<style scoped></style>
